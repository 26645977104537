
.section.cooperation {
  background-color: #fff;
  padding-bottom: 160px;
  .cooperation-type {
    max-width: 1240px;
    padding-top: 160px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    .item {
      width: 30%;
      margin-bottom: 110px;
      padding: 59px 20px;
      text-align: center;
      color: @text-color;
      font-size: 18px;
      box-shadow: 0 1px 5px 0 #87898633;
      border-radius: 4px;
      background-color: #fff;
      border: solid 1px #cac9c966;
      h1 {
        margin: 0;
        font-size: 36px;
        font-weight: 600;
        color: #5b5b5b;
      }
      h3 {
        font-size: 70px;
        padding-top: 15px;
        color: #65ac4b;
        font-weight: 500;
        font-family: adad;
        span {
          font-size: 24px;
        }
      }
      p {
        margin: 0;
        padding: 10px 0 0 0;
        line-height: 1.7;
        font-size: 18px;
      }
    }
  }
  .apply-btn {
    margin: 0 auto;
    display: block;
    width: 400px;
    padding: 10px 0;
    text-align: center;
    background-color: #61aa46;
    font-size: 20px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background-color: #44882a;
    }
    &:active {
      background-color: #2f5222;
    }
  }
}

@media (max-width: @screen-lg) {
  .section.cooperation {
    .cooperation-type {
      .item {
        padding: 80px 20px;
      }
    }
  }
}
@media (max-width: @screen-md) {
  .section.cooperation {
    .cooperation-type {
      padding-top: 25px;
      padding: 50px 20px !important;
      .item {
        width: 100%;
        margin-bottom: 25px;
      }
    }
    .apply-btn {
      width: auto;
      margin: 0 20px;
    }
  }
}

@media (max-width: 1280px) {
  .cooperation {
    padding-bottom: 100px !important;
  }
  .cooperation-type{
    padding: 0 70px;
    padding-top: 100px !important;
  }
}