@import './var.less';
@import './base.less';

.section {
  min-height: 200px;
}

.section h2 {
  font-size: 29px;
  color: #333;
  font-weight: normal;
  text-align: center;
  margin: 80px auto 0 auto;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: 1px;
  &::after {
    content: '';
    position: absolute;
    overflow: hidden;
    height: 3px;
    background: @primary-color;
    width: 60px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%)
  }
}
.textCenter {

}

@import './header.less';
@import './banner.less';
@import './func.less';
@import './case.less';
@import './cooperation.less';
@import './introduction.less';
@import './foot.less';
