body {
  font-size: @base-font-size;
  color: @base-font-color;
  font-family: @base-font-family;
  background-color: #fff;
}

.t-center {
  text-align: center;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.hide {
  display: none !important;
}
.row {
  display: flex;
  flex-direction: row;
}


