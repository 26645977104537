#contact.section {
  .list {
    position: relative;
    padding: 160px 10vw;
    background-color: #fff;
    color: #fff;
    text-align: center;
  }
  .contact-us {
    margin-top: 98px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      padding-left: 30px;
      color: #585858;
      font-size: 18px;
      line-height: 1.5;
    }
    .email {
      font-size: 24px;
      color: @primary-color;
      font-weight: bold;
    }
  }
  .wechat-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .wechat {
      text-align: center;
      margin-right: 160px;
      &:last-child {
        margin-right: 0;
      }
      .wechat-title {
        margin-top: 15px;
        font-size: 20px;
        line-height: 1.57;
        color: #585858;
      }
    }
  }
  .foot-input-group {
    margin-top: 120px;
    p {
      font-size: 24px;
      font-weight: 600;
      color: #464646;
    }
    .input-group {
      margin-top: 40px;
      input {
        width: 400px;
        vertical-align: top;
        border: 1px solid #61aa46;
        padding-left: 10px;
        &:focus {
          outline: none ;
        }
        &::-webkit-input-placeholder {
          color: #cecece;
        }
        &:-moz-placeholder {/* Firefox 18- */
          color: #cecece;
        }
        &::-moz-placeholder{/* Firefox 19+ */
          color: #cecece;
        }
        &:-ms-input-placeholder {
          color: #cecece;
        }
      }
      .phone-enter {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  p {
    line-height: 2;
    margin: 0;
  }
  .copyright {
    margin: 57px 0 0 0;
    .copyright-contact {
      margin-right: 6px;
      font-size: 22px;
      color: #fff;
      line-height: normal;
    }
    .copyright-email {
      color: #fff;
      font-size: 22px;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

.footer-record {
  padding: 45px 10vw 15px 10vw;
  background-color: #585858;
  font-size: 14px;
  text-align: center;
  color: #fff;
  ul {
    max-width: 650px;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: row wrap;
  }
  .footer-nav {
    a {
      cursor: pointer;
      display: inline-block;
      width: 100%;
      text-decoration: none;
      color: #fff;
      &:link {
        color: #fff;
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
      &:active {
        color: @primary-color;
        text-decoration: none;
      }
    }
  }
  .footer-contact {
    margin-top: 44px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    span:nth-child(2){
      padding: 0 10px;
      color: #ccc;
    }
  }
  .footer-rights {
    font-size: 12px;
    line-height: 1.2;
    color: #909090;
  }
}

@media (max-width: @screen-md) {
  #contact {
    display: none;
  }
  #footer-mobile,
  .about {
    display: flex;
  }
  .wechat,
  .contact-us {
    padding-bottom: 40px;
    text-align: center;
  }
  .wechat p{
    font-size: 16px;
  }
}

@media (max-width: @screen-sm) {
  .footer-record {
    ul {
      margin: 0;
      li {
        margin-bottom: 10px;
        flex-basis: 50%;
      }
    }
  }
  .input-group {
    input {
      width: 50%;
    }
    a {
      padding: 0 20px;
    }
  }
}
