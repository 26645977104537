.section.case {
  background-color: #f6f7f6;
  padding: 160px 0;
  .case-wrap {
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 50px 30px;
    border-radius: 9px;
    background-color: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(135, 137, 134, 0.2);
    font-size: 18px;
    color: #4a4a4a;
    line-height: 1.5;
    box-sizing: border-box;
  }
  .etu {
    width: 100%;
  }
  .case-introduce {
    padding: 0 30px;
    line-height: 1.7;
    font-weight: 300;
    .etu-signature {
      text-align: right;
    }
  }
  .case-logo {
    margin: 0 auto;
    width: 100%;
    display: inline-block;
    text-align: center;
    img {
      max-width: 80%;
      width: 300px;
    }
  }
  .case-info {
    margin: 0;
    padding: 36px 30px;
    font-size: 18px;
    color: @text-color;
    line-height: 1.7;
  }
  .case-box {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .case-wrap {
      width: 32%;
      margin: 0;
    }
  }
  .apply-btn {
    margin: 80px auto 0 auto;
    display: block;
    width: 400px;
    padding: 10px 0;
    text-align: center;
    background-color: #61aa46;
    font-size: 20px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background-color: #44882a;
    }
    &:active {
      background-color: #2f5222;
    }
  }
}

@media (max-width: @screen-md) {
  .section.case {
    padding:70px 20px !important;
    .case-box {
      flex-wrap: wrap;
      .case-wrap {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .section.case {
    padding: 50px 0; 
    .apply-btn {
      width: auto;
      margin: 0 10px;
    }
    .case-wrap{
      padding: 50px 0;
    }
  }
}

@media (max-width: 1280px) {
  .section.case {
    padding: 70px;
  }
}