section.banner {
  position: relative;
  min-height: 90vh;
  color: @primary-color;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  .title {
    margin-top: 190px;
    font-size: 60px;
    font-weight: 600;
    color: #464646;
  }
  .content {
    font-size: 30px;
    font-weight: 300;
    color: #464646;
  }
  .input-group {
    margin-top: 5%;
    input {
      width: 400px;
      vertical-align: top;
      border: 1px solid #61aa46;
      padding-left: 10px;
      &:focus {
        outline: none ;
      }
      &::-webkit-input-placeholder {
        color: #cecece;
      }
      &:-moz-placeholder {/* Firefox 18- */
        color: #cecece;
      }
      &::-moz-placeholder{/* Firefox 19+ */
        color: #cecece;
      }
      &:-ms-input-placeholder {
        color: #cecece;
      }
    }
    .phone-enter {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .banner-img {
  //   background: url('../assets/banner-wrap.svg') no-repeat;
  //   background-size: cover;
    max-width: 62.5%;
  //   height: 150vh;
    margin: 0 auto;
  }
  .introduce {
    padding-top: 50px;
    font-size: 20px;
    color: #464646;
    font-weight: 300;
  }
  .help-video {
    width: 70%;
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }
  .fill-square {
    width: 200%;
    height: 16%;
    margin-left: -50%;
    background-color: #f6f7f6;
    position: absolute;
    bottom: 0;
  }
}


@media (max-width: @screen-lg) {
  section.banner {
    .title {
      margin-top: 120px;
      font-size: 40px;
    }
    .content {
      font-size: 30px;
    }
    .introduce {
      padding-top: 40px;
      font-size: 18px;
    }
    .banner-img {
      max-width: 80%;
    }
    .help-video {
      width: 80%;
    }
  }
}

@media (max-width: @screen-sm) {
  section{
    padding: 10px 0 !important;
  }
  section.banner {
    min-height: 72vh;
    .title {
      font-size: 30px;
      margin-top: 95px;
    }
    .content {
      font-size: 20px;
    }
    .introduce {
      padding-top: 0px;
      font-size: 16px;
    }
    .banner-img {
      margin-top: 40px;
      max-width: 100%;
    }
    .input-group {
      input {
        width: 50%;
      }
      a {
        padding: 0 20px;
      }
    }
    .help-video {
      width: 90%;
    }
  }
}

@media (max-width: 1280px) {
  section.banner {
    padding: 0 70px;
  }
  .banner-img {
    max-width: 65%;
  }
}