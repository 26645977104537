
#introduction {
  background-color: #f6f7f6;
  .introduce-content {
    padding: 160px 0;
    max-width: 1240px;
    margin: 0 auto;
    h2 {
      position: relative;
      margin: 0 0 48px 0;
      padding: 0 0 16px 0;
      font-size: 24px;
      color: #5b5b5b;
      font-weight: bold;
      text-align: center;
      &::after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        display: inline-block;
        width: 160px;
        height: 4px;
        background-color: #61aa46;
      }
    }
    p {
      font-size: 18px;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: justify;
      color: #5b5b5b;
      text-indent: 2em;
    }
  }
}

@media (max-width: @screen-md) {
  #introduction {
    .background {
      .title {
        font-size: 30px;
      }
    }
    .introduce-content {
      padding: 100px 0;
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
        padding: 0 20px;
      }
    }
  }
}
@media (max-width: 1280px) {
  .introduce-content {
    padding: 100px 20px !important;
  }
}