@border-color: #eee;
.func {
  width: 100%;
  background-color: #f6f7f6;
}
.func-wrap {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
  max-width: 1200px;
  min-height: 678px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 100px 0;
  .block {
    margin: 35px 0;
    flex-basis: 33.3%;
    text-align: center;
    min-height: 300px;
    .icon {
      width: 180px;
    }
    h3 {
      margin: 0;
      padding-top: 32px;
      font-size: 24px;
      font-weight: bold;
      color: @text-color;
    }
    p {
      margin: 0;
      padding-top: 20px;
      font-size: 18px;
      color: @text-color;
      line-height: 28px;
    }
  }
}

.func-detail-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 160px 12vw;
  background-color: #fff;

  .block-detail {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 160px;
    .content-text {
      width: 50%;
      align-self: center;
      h1 {
        margin: 0;
        border-left: 8px solid @primary-color;
        padding-left: 12px;
        font-size: 24px;
        color: @primary-color;
        font-weight: bold;
        line-height: 1;
      }
      h2 {
        margin: 36px 0 0 0;
        text-align: left;
        font-size: 20px;
        color: @text-color;
        line-height: 1;
        &::after {
          width: 0;
          height: 0;
        }
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        color: @text-color;
        font-weight: 300;
      }
    }
    .content-icon {
      margin: 0 auto;
      width: 38%;
    }
    .order1 {
      order: 1;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

}

@media (max-width: @screen-md) {
  .func-wrap {
    justify-content: center;
  }
  .func-detail-wrap {
    .block-detail {
      flex-wrap: wrap;
      margin-bottom: 60px;
      .content-text, .content-icon {
        width: 100%;
      }
      .order1 {
        order: 0;
      }
    }
  }
}

@media (max-width: @screen-sm) {
  .func-wrap {
    padding: 20px  !important;
    .block {
      flex-basis: 50%;
      margin: 35px 0 0 0;
      min-height: 230px;
      .icon {
        width: 140px;
      }
      p{
        font-size: 16px;
      }
    }
  }
  .func-detail-wrap {
    .block-detail{
      margin-bottom: 20px;
    }
  }

}

@media (max-width: 1280px) {
  .func-wrap{
    padding: 10px 70px;
    .block {
      margin: 20px 0 0 0;
      .icon {
        width: 140px;
      }
    }
  }
  .func-detail-wrap {
    padding: 100px 10vw !important;
    .block-detail {
      margin-bottom: 80px;
    }
  }
}
