/* 顶部菜单栏 */

// 登录按钮hover效果
.loginHover {
  background: @primary-color;
  color: white;
  border-color: transparent
}

.header {
  position: fixed;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 222;
  transition: all 300ms;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width:1440px;
    min-height: 50px;
    margin: 0 auto;
  }
  menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .menu-list {
    display: none;
    transition: .4s all ease;
  }
  menu a {
    display: inline-block;
    width: auto;
    padding: 0 30px;
    height: 92px;
    line-height: 90px;
    color: #464646;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    transition: all 200ms;
    border-bottom: 2px solid transparent;
    &:hover {
      color: @primary-color;
      border-bottom: 2px solid #c1e3b5;
    }
    &.active {
      border-bottom: 2px solid @primary-color;
    }
  }
  .apply-try{
    display: inline-block;
    padding: 0 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    background-color: @primary-color;
    border: 1px solid transparent;
    color: #fff;
    &:hover {
      text-decoration: none;
      background-color: #44882a;
      border-bottom: none;
      color: #fff;
    }
  }
}

.input-group {
  .phone-number {
    width: 200px;
    height: 40px;
    line-height: 40px;
    
  }
  .apply-try {
    display: inline-block;
    padding: 0 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    background-color: @primary-color;
    border: 1px solid transparent;
    color: #fff;
    &:hover {
      text-decoration: none;
      background-color: #44882a;
      border-bottom: none;
      color: #fff;
    }
  }
}

.logo {
  margin: 0;
  margin-right: 100px;
  align-items: center;
  color: #fff;
  transition: color 500ms;
  flex-shrink: 0;
  width: 120px;
  height: auto;
}

.headroom--not-top.header {
  .logo {
    color: #333;
  }
  menu a {
    color: #333;
    &:hover {
      color: @primary-color;
    }
  }
  .apply-try{
    color: #fff;
    &:hover {
      text-decoration: none;
      background-color: #44882a;
      border-bottom: none;
      color: #fff;
    }
  }
}
#menu-icon {
    display: none;
    cursor: pointer;
    background: white;
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
}
#menu-icon span {
    position: absolute;
    width: 40px;
    height: 4px;
    top: 50%;
    background: #262626;
    border-radius: 2px;
    overflow: hidden;
    transition: all 0.3s linear;
}
#menu-icon span::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    background: gray;
    transition: all 0.3s linear;
}
#menu-icon span:nth-child(1) {
    animation: span-first-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
#menu-icon span:nth-child(2) {
    animation: span-second-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
#menu-icon span:nth-child(3) {
    animation: span-third-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
#menu-icon.on:hover span::before {
    width: 100%;
    transition: all 0.3s linear;
}
#menu-icon.on span:nth-child(1) {
    animation: span-first-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
#menu-icon.on span:nth-child(2) {
    animation: span-second-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
}
#menu-icon.on span:nth-child(3) {
    animation: span-third-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes span-first-on {
    0% {
        transform: translate(-50%, -300%);
    }
    30% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
@keyframes span-first-off {
    0% {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    30% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -300%);
    }
}
@keyframes span-second-on {
    0% {
        transform: translate(-50%, -50%);
    }
    25% {
        background: gray;
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-150%, -50%) scale(0);
    }
}
@keyframes span-second-off {
    0% {
        transform: translate(-150%, -50%) scale(0);
    }
    25% {
        background: gray;
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}
@keyframes span-third-on {
    0% {
        transform: translate(-50%, 200%);
    }
    30% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(-50%, -50%) rotate(45deg);
    }
}
@keyframes span-third-off {
    0% {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    30% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, 200%);
    }
}
@media  (max-width: @screen-lg) {
  .header {
    padding: 10px 20px;
    menu {
      a{
        padding: 0 20px;
      }
    }
    .logo {
      width: 120px;
    }
  }
}

@media (max-width: @screen-sm) {
  .header {
		padding: 10px 20px !important;
	}
}
@media (max-width: 945px) {
	.header{
		padding: 10px 20px !important;
	}
	menu {
		display: none !important;
	}
	#menu-icon {
		display: block;
	}
	.menu-list {
		position: absolute;
		left: 0;
		top: 70px;
		background: rgba(0, 0, 0, 0.8);
		width: 100%;
		transform: scaleY(0);
		transform-origin: top;
		overflow: hidden;
        display: block !important;
        .apply-try {
            margin-bottom: 10px;
        }
		a {
			display: block;
			line-height: 40px;
			color: #fff;
		}
	}
}
@media (max-width: 1280px) {
	.header {
		padding: 0 70px;
	}
}
.fl {
  float: left;
}
.fr {
  float: right;
}
